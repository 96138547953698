import { parseISO } from 'date-fns';
import { ALLRATES } from '../helpers/constants';
import useMeasuringPoints from './useMeasuringPoints';
import useMeasuringData from './useMeasuringData';

function useData(path, from, to, previous) {
  const measuringPoints = useMeasuringPoints();
  const solar = useMeasuringData(measuringPoints && measuringPoints.Zonnepanelen.id, path, from, to);
  const meter = useMeasuringData(measuringPoints && measuringPoints.Meter.id, path, from, to);
  if (previous) {
    // prefetch previous data
    useMeasuringData(measuringPoints && measuringPoints.Zonnepanelen.id, path, previous, from);
    useMeasuringData(measuringPoints && measuringPoints.Meter.id, path, previous, from);
  }
  if (!solar || !meter) {
    return undefined;
  }
  const data = [...solar, ...meter];
  const result = new Map();
  ALLRATES.forEach((rate) => result.set(rate, []));
  data.forEach((o) => {
    const {
      rate, quantity, date, datetime,
    } = o;
    result.get(rate).push({ quantity: Number(quantity), ts: parseISO(date || datetime) });
  });
  return result;
}

export default useData;
