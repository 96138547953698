import React from 'react';
import {
  addWeeks, format, parseISO, startOfDay, subWeeks,
} from 'date-fns';
import { useHistory } from 'react-router-dom';
import dateString from '../helpers/dateString';
import useData from '../hooks/useData';
import usePathname from '../hooks/usePathname';
import Table from './Table';
import MyBarChart from './MyBarChart';
import getTimePoints from '../helpers/getTimePoints';
import getPowerData from '../helpers/getPowerData';
import tenMinutesAgo from '../helpers/tenMinuteAgo';
import isSmall from '../helpers/isSmall';
import getChartData from '../helpers/getChartData';
import getTotals from '../helpers/getTotals';
import Totals from './Totals';
import Spinner from './Spinner';

function getRange(dateStr) {
  if (dateStr) {
    const start = parseISO(dateStr);
    return {
      start,
      end: addWeeks(start, 1),
    };
  }
  const end = startOfDay(tenMinutesAgo());
  return {
    start: subWeeks(end, 1),
    end,
  };
}

function Week() {
  const { date } = usePathname();
  const history = useHistory();
  const { start, end } = getRange(date);
  const data = useData('datapoint/days', dateString(start), dateString(end));
  if (!data) {
    return <Spinner />;
  }
  const timePoints = getTimePoints('days', start, end);
  const totals = getTotals(timePoints, data);
  const powerData = getPowerData(timePoints, data, 'daily');
  const chartData = getChartData(powerData, isSmall() ? 'EEEEEE' : 'E d', 1);

  function handleClick(info) {
    const index = info.activeTooltipIndex;
    const newDate = powerData[index].timePoint;
    const newUrl = `/day/${format(newDate, 'yyyy-MM-dd')}`;
    history.push(newUrl);
  }

  return (
    <>
      <div className="d-sm-flex justify-content-sm-between">
        <h2 className="card-title">Electricity by Day (kWh)</h2>
        <Totals data={totals} decimals={1} />
      </div>
      <MyBarChart data={chartData} onClick={handleClick} />
      <Table data={chartData} timeLabel="date" />
    </>
  );
}

export default Week;
