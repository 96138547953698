/* eslint-disable react/jsx-props-no-spreading */
import {
  addDays, subDays, parseISO, startOfDay,
} from 'date-fns';
import React from 'react';

import dateString from '../helpers/dateString';
import useData from '../hooks/useData';
import usePathname from '../hooks/usePathname';
import Table from './Table';
import getTimePoints from '../helpers/getTimePoints';
import getPowerData from '../helpers/getPowerData';
import MyAreaChart from './MyAreaChart';
import tenMinutesAgo from '../helpers/tenMinuteAgo';
import isSmall from '../helpers/isSmall';
import getChartData from '../helpers/getChartData';
import getTotals from '../helpers/getTotals';
import Totals from './Totals';
import Spinner from './Spinner';

function Day() {
  const { date } = usePathname();
  const start = startOfDay(date ? parseISO(date) : tenMinutesAgo());
  const end = addDays(start, 1);
  const data = useData('datapoints', dateString(start), dateString(end), dateString(subDays(start, 1)));
  if (!data) {
    return <Spinner />;
  }
  const sample = 5;
  const xInterval = isSmall() ? (360 / sample) - 1 : (180 / sample) - 1;
  const timePoints = getTimePoints('minutes', start, end, sample);
  const totals = getTotals(timePoints, data);
  const powerData = getPowerData(timePoints, data, 'power');
  const chartData = getChartData(powerData, 'HH:mm', 2);
  return (
    <>
      <div className="d-sm-flex justify-content-sm-between">
        <h2 className="card-title">Power (kW)</h2>
        <Totals data={totals} decimals={2} />
      </div>
      <MyAreaChart data={chartData} xInterval={xInterval} />
      <Table data={chartData} timeLabel="time" />
    </>
  );
}

export default Day;
