/*
* Simple Storage hook
* does not synchronize between instances
*/

import { useState } from 'react';

function useStorage(storage, key, defaultValue) {
  const [state, setState] = useState(getInitialValue());

  function getInitialValue() {
    const current = storage.getItem(key);
    return current === undefined || current === null ? defaultValue : JSON.parse(current);
  }

  function setValue(value) {
    setState(value);
    storage.setItem(key, JSON.stringify(value));
  }
  return [state, setValue];
}

export function useLocalStorage(key, defaultValue) {
  return useStorage(localStorage, key, defaultValue);
}

export function useSessionStorage(key, defaultValue) {
  return useStorage(sessionStorage, key, defaultValue);
}
