import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  addDays, addHours, addMonths, addWeeks, addYears,
  format, parseISO, startOfHour, startOfMonth, startOfWeek, startOfYear, subDays, subHours,
} from 'date-fns';
import usePathname from '../hooks/usePathname';
import dateString from '../helpers/dateString';

function Navigation() {
  const { base, page, date } = usePathname();
  const pages = ['hour', 'day', 'week', 'month', 'year'];
  const dateObject = date && parseISO(date);

  const isHour = page === 'hour';
  const currentDate = date ? dateObject : new Date();
  const startDate = isHour ? startOfHour(currentDate) : subDays(currentDate, 1);
  let previousDate;
  switch (page) {
    case 'hour': previousDate = subHours(startDate, 1); break;
    case 'day': previousDate = startDate; break;
    case 'week': previousDate = startOfWeek(startDate, { weekStartsOn: 1 }); break;
    case 'month': previousDate = startOfMonth(startDate); break;
    case 'year': previousDate = startOfYear(startDate); break;
    default: return null;
  }
  const previousDateLink = getDateLink(previousDate, isHour);
  let nextDate = null;
  let nextDateLink = null;
  if (date) {
    switch (page) {
      case 'hour': nextDate = addHours(previousDate, 2); break;
      case 'day': nextDate = addDays(previousDate, 2); break;
      case 'week': nextDate = addWeeks(previousDate, 2); break;
      case 'month': nextDate = addMonths(previousDate, 2); break;
      case 'year': nextDate = addYears(previousDate, 2); break;
      default:
    }
    if (nextDate > new Date()) {
      nextDateLink = `${base}${page}`;
    } else {
      nextDateLink = getDateLink(nextDate, isHour);
    }
  }

  function getDateLink(dt, withTime) {
    const str = typeof dt === 'string' ? dt : dateString(dt, withTime);
    return `${base}${page}/${str}`;
  }

  function getTitle() {
    switch (page) {
      case 'hour': return date ? format(dateObject, 'E d MMMM yyyy HH:mm') : 'Now';
      case 'day': return date ? format(dateObject, 'E d MMMM yyyy') : 'Today';
      case 'week': return date ? format(dateObject, "'Week of 'd MMMM yyyy") : 'Last 7 days';
      case 'month': return date ? format(dateObject, 'MMMM yyyy') : 'Last 30 days';
      case 'year': return date ? format(dateObject, 'yyyy') : 'Last 12 months';
      default: return null;
    }
  }

  return (
    <nav>
      <ul className="nav nav-tabs">
        {pages.map((pg) => (
          <li key={pg} className="nav-item">
            <Link
              className={classnames(['nav-link', 'text-capitalize', 'px-2', 'px-sm-3', { active: page === pg }])}
              to={`${base}${pg}`}
            >
              {pg}
            </Link>
          </li>
        ))}
      </ul>
      <div className="d-flex flex-row border align-items-center justify-content-between">
        <Link to={`${base}${page}`} className="mx-3">Today</Link>
        <span className="font-weight-bold text-center text-nowrap">
          <Link type="button" className="btn btn-link" to={previousDateLink}>&laquo;</Link>
          <span className="mx-1 mx-sm-3">{getTitle()}</span>
          {nextDateLink
           && <Link type="button" className="btn btn-link" disabled={!nextDateLink} to={nextDateLink}>&raquo;</Link>}
        </span>
        <span />
      </div>
    </nav>
  );
}

export default Navigation;
