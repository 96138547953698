import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DL = styled.dl`
  display: flex;
  dt {
    &::after {
      content: ':';
    }
    text-transform: capitalize;
    margin-right: 0.5rem;
  }
  dd + dt {
    margin-left: 1rem;
  }
`;

function Totals(props) {
  const { data, decimals } = props;
  return (
    <DL>
      {['solar', 'usage'].map((key) => (
        <React.Fragment key={key}>
          <dt>{key}</dt>
          <dd>{`${data[key].toFixed(decimals)} kWh`}</dd>
        </React.Fragment>
      ))}
    </DL>
  );
}

Totals.propTypes = {
  data: PropTypes.object.isRequired,
  decimals: PropTypes.number.isRequired,
};

export default Totals;
