import { format } from 'date-fns';

function getChartData(data, dateFormat, decimals) {
  const fix = (v) => (v === undefined ? '-' : v.toFixed(decimals));
  return data.map((o) => ({
    key: format(o.timePoint, dateFormat),
    solar: fix(o.solar),
    usage: fix(o.usage),
  }));
}

export default getChartData;
