import React from 'react';
import { Route } from 'react-router-dom';
import useToken from '../hooks/useToken';

function PrivateRoute(props) {
  const { accessToken } = useToken();
  if (!accessToken) {
    return null;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} />;
}

export default PrivateRoute;
