import React from 'react';
import PropTypes from 'prop-types';
import { series } from '../helpers/constants';

function Table(props) {
  const { data, timeLabel } = props;
  if (!data) {
    return <span>Loading...</span>;
  }
  function showValue(v) {
    if (typeof v !== 'number') {
      return v;
    }
    return v || '-';
  }
  const fields = series.map((o) => o.key);
  return (
    <table className="table table-sm table-hover">
      <thead>
        <tr className="text-capitalize text-right">
          <th className="text-center">{timeLabel}</th>
          {fields.map((f) => <th key={f}>{f}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.key} className="text-right">
            <td className="text-center">{row.key}</td>
            {fields.map((f) => <td key={f}>{showValue(row[f])}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.array,
  timeLabel: PropTypes.string.isRequired,
};

export default Table;
