import React from 'react';
import PropTypes from 'prop-types';

function Spinner(props) {
  const { className } = props;
  return (
    <div className={`spinner-border mx-auto text-secondary ${className}`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
}

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

export default Spinner;
