import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ClientOAuth2 from 'client-oauth2';
import { auth2 } from '../config';
import { useLocalStorage } from '../hooks/useStorage';

const auth = new ClientOAuth2(auth2);

export const AuthContext = createContext();

function AuthProvider(props) {
  const { children } = props;
  const [token, setToken] = useLocalStorage('authToken', {});
  const [parsed, setParsed] = useState(false);
  const { hash, href, search } = window.location;
  const history = useHistory();
  if (search && search.includes('state=')) {
    return <pre>{href}</pre>;
  }
  const hasState = hash.startsWith('#state=');
  async function oauth2Callback(uri) {
    const { data, expires } = await auth.token.getToken(uri);
    setToken({
      accessToken: data.access_token,
      expires,
    });
    history.replace(href.replace(/#.*/, ''));
  }
  if (hasState && !parsed) {
    setParsed(true);
    oauth2Callback(href);
  }
  useEffect(() => {
    if (hasState) {
      return;
    }
    if (!token.expires || new Date(token.expires) < new Date()) {
      window.location.href = auth.token.getUri();
    }
  }, []);
  return (
    <AuthContext.Provider value={token}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
