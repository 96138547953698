import { useLocation } from 'react-router-dom';

function usePathname() {
  const { pathname } = useLocation();
  const match = pathname.match(/(.*)(hour|day|week|month|year)(\/20\d{2}-\d{1,2}-\d{1,2}[T:\d]*)?/);
  if (!match) {
    return { base: pathname };
  }
  const [base, page, dateStr] = match.slice(1);
  const date = dateStr && `${dateStr.slice(1)}`;
  return { base, page, date };
}

export default usePathname;
