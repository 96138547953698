import { addDays, addMinutes, addMonths } from 'date-fns';

function getTimePoints(unit, start, end, step = 1) {
  const result = [];
  function next(dt) {
    switch (unit) {
      case 'minutes':
        return addMinutes(dt, step);
      case 'days':
        return addDays(dt, step);
      case 'months':
        return addMonths(dt, step);
      default:
        throw new Error('Invalid unit');
    }
  }
  for (let dt = start; dt <= end; dt = next(dt)) {
    result.push(dt);
  }
  return result;
}

export default getTimePoints;
