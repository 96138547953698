/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,
} from 'recharts';

import chartDimensions from '../helpers/chartDimensions';
import { series } from '../helpers/constants';

function MyAreaChart(props) {
  const {
    data, xInterval, tickCount, domain,
  } = props;
  const dimensions = chartDimensions();
  if (!dimensions) {
    return null;
  }
  return (
    <>
      <AreaChart {...dimensions} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" interval={xInterval} />
        <defs>
          {series.map(({ key, stroke }) => (
            <linearGradient key={key} id={key} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={stroke} stopOpacity={0.8} />
              <stop offset="95%" stopColor={stroke} stopOpacity={0.1} />
            </linearGradient>
          ))}
        </defs>
        <YAxis domain={domain} allowDataOverflow tickCount={tickCount} width={30} tickFormatter={(v) => v.toFixed(1)} />
        <Tooltip />
        <Legend />
        {series.map(({ key, ...colors }) => (
          <Area dataKey={key} key={key} {...colors} fill={`url(#${key})`} />
        ))}
      </AreaChart>
    </>
  );
}

MyAreaChart.propTypes = {
  data: PropTypes.array.isRequired,
  xInterval: PropTypes.number,
  tickCount: PropTypes.number,
  domain: PropTypes.array,
};

MyAreaChart.defaultProps = {
  xInterval: 0,
  tickCount: 7,
  domain: [0, 3],
};

export default MyAreaChart;
