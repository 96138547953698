import axios from 'axios';
import { useQuery } from 'react-query';
import useToken from './useToken';

const DEFAULT_STALE_TIME = 300000; // ms
const PROXY_URL = 'https://proxy.harryonline.net/';
const API_URL = 'https://enelogic.com/api';

function useFetch(url, options = {}) {
  const { accessToken } = useToken();
  const staleTime = options.staleTime || DEFAULT_STALE_TIME;
  function fetch() {
    // eslint-disable-next-line no-console
    console.log('fetch', url);
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'X-Proxy-URL': `${API_URL}${url}`,
      'X-Proxy-Cache': staleTime / 1000,
    };
    return axios({
      url: PROXY_URL,
      headers,
    });
  }
  const { data, ...rest } = useQuery(url, fetch, { staleTime, ...options });
  return { response: data, ...rest };
}

export default useFetch;
