import useFetch from './useFetch';

function useMeasuringPoints() {
  const { isLoading, error, response } = useFetch('/measuringpoints');
  if (isLoading) {
    // console.log('Loading measuring points...');
    return null;
  }
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
  // eslint-disable-next-line no-debugger
  const result = {};
  response.data.forEach((d) => {
    if (d.label) {
      result[d.label] = d;
    }
  });
  return result;
}

export default useMeasuringPoints;
