import React from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AuthProvider from './components/AuthProvider';
import Hour from './components/Hour';
import Day from './components/Day';
import Month from './components/Month';
import Navigation from './components/Navigation';
import Week from './components/Week';
import Year from './components/Year';
import { red700 } from './config/colors';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';

const GlobalStyle = createGlobalStyle`
  h2.card-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${red700};
  }
`;

function App() {
  const queryClient = new QueryClient();
  return (
    <Router>
      <GlobalStyle />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <div className="p-3 container">
            <h1>Enelogic Monitor</h1>
            <Navigation />
            <div id="chart-card" className="card p-3">
              <Switch>
                <PrivateRoute path="/hour/:date?">
                  <Hour />
                </PrivateRoute>
                <PrivateRoute path="/day/:date?">
                  <Day />
                </PrivateRoute>
                <PrivateRoute path="/week/:date?">
                  <Week />
                </PrivateRoute>
                <PrivateRoute path="/month/:date?">
                  <Month />
                </PrivateRoute>
                <PrivateRoute path="/year/:date?">
                  <Year />
                </PrivateRoute>
                <PrivateRoute path="/">
                  <Redirect to="/day" />
                </PrivateRoute>
              </Switch>
            </div>
            <Footer />
          </div>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
