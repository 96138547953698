import { format } from 'date-fns';

const FORMAT = 'yyyy-MM-dd';
const TIMEFORMAT = "yyyy-MM-dd'T'HH:mm";

function dateString(date, withTime) {
  return format(date, withTime ? TIMEFORMAT : FORMAT);
}

export default dateString;
