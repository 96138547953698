/* eslint-disable react/jsx-props-no-spreading */
import {
  addDays, addHours, parseISO, startOfHour,
} from 'date-fns';
import React from 'react';

import dateString from '../helpers/dateString';
import useData from '../hooks/useData';
import usePathname from '../hooks/usePathname';
import Table from './Table';
import getTimePoints from '../helpers/getTimePoints';
import getPowerData from '../helpers/getPowerData';
import MyAreaChart from './MyAreaChart';
import tenMinutesAgo from '../helpers/tenMinuteAgo';
import isSmall from '../helpers/isSmall';
import getChartData from '../helpers/getChartData';
import getTotals from '../helpers/getTotals';
import Totals from './Totals';
import Spinner from './Spinner';

function getRange(dateStr) {
  const start = startOfHour(dateStr ? parseISO(dateStr) : tenMinutesAgo());
  return {
    start,
    end: addHours(start, 1),
  };
}

function Hour() {
  const { date } = usePathname();
  const { start, end } = getRange(date);
  const data = useData('datapoints', dateString(start), dateString(addDays(start, 1)));
  if (!data) {
    return <Spinner />;
  }
  const timePoints = getTimePoints('minutes', start, end);
  const totals = getTotals(timePoints, data);
  const powerData = getPowerData(timePoints, data, 'power');
  const chartData = getChartData(powerData, 'HH:mm', 2);
  return (
    <>
      <div className="d-sm-flex justify-content-sm-between">
        <h2 className="card-title">Power (kW)</h2>
        <Totals data={totals} decimals={2} />
      </div>
      <MyAreaChart data={chartData} xInterval={isSmall() ? 14 : 9} />
      <Table data={chartData} timeLabel="time" />
    </>
  );
}

export default Hour;
