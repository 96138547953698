const authList = [
  {
    clientId: '9990_6b3gvwzys1gcssk840gs0csc4kgwssow4wcs0w40og8gc08cck',
    authorizationUri: 'https://enelogic.com/oauth/v2/auth',
    redirectUri: 'http://localhost:3000',
    scopes: ['account'],
  },
  {
    clientId: '9997_4u0tmxmp4a2okc84cwk8404s88w40wcoo4gggco0cokk4cs000',
    authorizationUri: 'https://enelogic.com/oauth/v2/auth',
    redirectUri: 'https://enelogic.harryonline.net',
    scopes: ['account'],
  },
];

const currentAuth = authList.find((o) => o.redirectUri.includes(window.location.origin));

const auth2 = {
  ...currentAuth,
  authorizationUri: 'https://enelogic.com/oauth/v2/auth',
  scopes: ['account'],
};

export default auth2;
