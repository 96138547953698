/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,
} from 'recharts';

import chartDimensions from '../helpers/chartDimensions';
import { series } from '../helpers/constants';

function MyBarChart(props) {
  const {
    data, xInterval, tickCount, domain, onClick,
  } = props;
  const dimensions = chartDimensions();
  if (!dimensions) {
    return null;
  }

  return (
    <>
      <BarChart {...dimensions} data={data} onClick={onClick}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" interval={xInterval} />
        <YAxis domain={domain} allowDataOverflow width={30} tickCount={tickCount} />
        <Tooltip />
        <Legend />
        {series.map(({ key, ...colors }) => <Bar dataKey={key} key={key} {...colors} />)}
      </BarChart>
    </>
  );
}

MyBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  xInterval: PropTypes.number,
  tickCount: PropTypes.number,
  domain: PropTypes.array,
  onClick: PropTypes.func,
};

MyBarChart.defaultProps = {
  xInterval: 0,
  tickCount: 5,
  domain: [0, 20],
  onClick: undefined,
};

export default MyBarChart;
