import { ALLRATES, RATE_DOWN_1, RATE_DOWN_2 } from './constants';

function mul(rate) {
  return [RATE_DOWN_1, RATE_DOWN_2].includes(rate) ? -1 : 1;
}

function getUsage(data) {
  if (ALLRATES.some((rate) => typeof data[rate] !== 'number')) {
    return undefined;
  }
  let result = 0;
  ALLRATES.forEach((rate) => {
    result += mul(rate) * data[rate];
  });
  return result;
}

export default getUsage;
