import React from 'react';
import {
  addYears, startOfMonth, format, parseISO, subYears,
} from 'date-fns';
import { useHistory } from 'react-router-dom';
import dateString from '../helpers/dateString';
import useData from '../hooks/useData';
import usePathname from '../hooks/usePathname';
import Table from './Table';
import MyBarChart from './MyBarChart';
import getTimePoints from '../helpers/getTimePoints';
import getPowerData from '../helpers/getPowerData';
import tenMinutesAgo from '../helpers/tenMinuteAgo';
import isSmall from '../helpers/isSmall';
import getChartData from '../helpers/getChartData';
import getTotals from '../helpers/getTotals';
import Totals from './Totals';
import Spinner from './Spinner';

function getRange(dateStr) {
  if (dateStr) {
    const start = parseISO(dateStr);
    return {
      start,
      end: addYears(start, 1),
    };
  }
  const end = startOfMonth(tenMinutesAgo());
  return {
    start: subYears(end, 1),
    end,
  };
}

function Year() {
  const { date } = usePathname();
  const history = useHistory();
  const { start, end } = getRange(date);
  const data = useData('datapoint/months', dateString(start), dateString(end));
  if (!data) {
    return <Spinner />;
  }
  const timePoints = getTimePoints('months', start, end);
  const totals = getTotals(timePoints, data);
  const powerData = getPowerData(timePoints, data, 'monthly');
  const chartData = getChartData(powerData, 'MMM', 0);

  function handleClick(info) {
    const index = info.activeTooltipIndex;
    const newDate = powerData[index].timePoint;
    const newUrl = `/month/${format(newDate, 'yyyy-MM-dd')}`;
    history.push(newUrl);
  }
  return (
    <>
      <div className="d-sm-flex justify-content-sm-between">
        <h2 className="card-title">Electricity by Month (kWh)</h2>
        <Totals data={totals} decimals={0} />
      </div>
      <MyBarChart
        data={chartData}
        domain={[0, 500]}
        tickCount={6}
        onClick={handleClick}
        xInterval={isSmall() ? 2 : 0}
      />
      <Table data={chartData} timeLabel="date" />
    </>
  );
}

export default Year;
