import dateString from '../helpers/dateString';
import useFetch from './useFetch';

function useMeasuringData(pointId, path, from, to) {
  const url = pointId && `/measuringpoints/${pointId}/${path}/${from}/${to}`;
  const staleTime = to >= dateString(new Date()) ? 60000 : undefined;
  const { isLoading, error, response } = useFetch(url, { staleTime, enabled: !!pointId });
  if (isLoading) {
    // console.log(`Loading data for ${pointId}...`);
    return null;
  }
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
  return response.data;
}

export default useMeasuringData;
