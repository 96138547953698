import { first, last } from 'lodash';
import { ALLRATES, RATE_SOLAR } from './constants';
import getUsage from './getUsage';
import interpolate from './interpolate';

function getFirst(ts, list) {
  // If first value > ts, will be extrapolated
  const idx = list.slice(1).findIndex((r) => r.ts > ts);
  if (idx === -1) {
    return last(list).quantity;
  }
  return interpolate(ts, list[idx], list[idx + 1]);
}

function getLast(ts, list) {
  // If last value < ts, will be used as is
  const lastValue = last(list);
  if (lastValue.ts < ts) {
    return lastValue.quantity;
  }
  let idx = list.length - 2;
  while (idx > 0 && list[idx].ts > ts) {
    idx--;
  }
  return interpolate(ts, list[idx], list[idx + 1]);
}

function getTotals(timePoints, data) {
  const start = first(timePoints);
  const end = last(timePoints);

  const rateTotal = {};
  ALLRATES.forEach((rate) => {
    rateTotal[rate] = getLast(end, data.get(rate)) - getFirst(start, data.get(rate));
  });
  return {
    solar: rateTotal[RATE_SOLAR],
    usage: getUsage(rateTotal),
  };
}

export default getTotals;
