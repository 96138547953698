import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ICON_SOURCE = 'https://s3-eu-west-1.amazonaws.com/logos.harryonline/ho_logo_24x26.png';

const Img = styled.img`
  height: 10px;
`;

function Footer(props) {
  const { className } = props;
  return (
    <footer className={className}>
      Developed by Harry Oosterveen,
      <a href="https://www.harryonline.net/" className="ml-2 font-italic">
        HarryOnline
        <Img alt="HarryOnline logo" className="ml-2" src={ICON_SOURCE} />
      </a>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: 'text-center small mt-4',
};

export default Footer;
