function chartDimensions() {
  const cardElem = document.getElementById('chart-card');
  if (!cardElem) {
    return null;
  }
  const width = cardElem.offsetWidth - 30;
  const height = Math.max(Math.round(width * 0.5), 300);
  return { height, width };
}

export default chartDimensions;
