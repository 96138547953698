import {
  differenceInDays, differenceInSeconds, isBefore,
} from 'date-fns';
import { ALLRATES, RATE_SOLAR } from './constants';
import getUsage from './getUsage';
import interpolate from './interpolate';

function getPower(t0, t1, dE, type) {
  if (type === 'power') {
    const seconds = differenceInSeconds(t1, t0);
    return 3600.0 * (dE / seconds);
  }
  const days = differenceInDays(t1, t0);
  if (type === 'daily') {
    return dE / days;
  }
  const months = Math.round(days / 30);
  return dE / months;
}

function getPowerForRate(timePoints, list, type) {
  // eslint-disable-next-line no-debugger
  let i = 1;
  const result = new Map();
  let lastQuantity;
  let lastTime;
  let currentQuantity;
  // Invariant: list[j] not before timePoints
  timePoints.forEach((curTime, idx) => {
    while (list[i] && isBefore(list[i].ts, curTime)) {
      i++;
    }
    if (list[i]) {
      currentQuantity = interpolate(curTime, list[i - 1], list[i]);
      if (idx > 0) {
        const power = getPower(lastTime, curTime, currentQuantity - lastQuantity, type);
        result.set(lastTime, power);
      }
    }
    lastTime = curTime;
    lastQuantity = list[i] ? currentQuantity : undefined;
  });
  return result;
}

function getPowerData(timePoints, data, type) {
  const powerData = new Map();
  Array.from(data.keys()).forEach((rate) => {
    powerData.set(rate, getPowerForRate(timePoints, data.get(rate), type));
  });

  function calcUsage(tp) {
    const powerValues = {};
    ALLRATES.forEach((rate) => {
      powerValues[rate] = powerData.get(rate).get(tp);
    });
    return getUsage(powerValues);
  }

  return timePoints.slice(0, -1)
    .map((timePoint) => ({
      timePoint,
      solar: powerData.get(RATE_SOLAR).get(timePoint),
      usage: calcUsage(timePoint),
    }));
  // .filter((o) => o.solar !== undefined || o.usage !== undefined);
}

export default getPowerData;
