import {
  green200, green500, red200, red500,
} from '../config/colors';

export const series = [
  { key: 'solar', stroke: green500, fill: green200 },
  { key: 'usage', stroke: red500, fill: red200 },
];

export const RATE_UP_1 = 181;
export const RATE_UP_2 = 182;
export const RATE_SOLAR = 280;
export const RATE_DOWN_1 = 281;
export const RATE_DOWN_2 = 282;
export const ALLRATES = [RATE_SOLAR, RATE_UP_1, RATE_UP_2, RATE_DOWN_1, RATE_DOWN_2];
